import type { BaseConfig } from '@iheartradio/web.config';
import { isUndefined, loadScript, waitUntil } from '@iheartradio/web.utilities';
import type { Logger } from '@iheartradio/web.utilities/create-logger';
import { mergeObjects } from '@iheartradio/web.utilities/object';
import { useEffect } from 'react';

import { eventType } from './../schemas.js';
import type { Analytics } from './../types.js';

export type AdobeEventType = 'trackAction' | 'trackPage';

declare global {
  interface Window {
    _satellite: {
      getVisitorId?: () => {
        getMarketingCloudVisitorID: () => string | undefined;
      };
      track: (event: AdobeEventType) => void;
      visitorSessionCount?: () => number;
    };
    analyticsData: {
      global?: Record<string, any>;
      events?: any;
    };
    tracker: {
      version: string;
    };
  }
}

export type AdobeProps = {
  analytics: Analytics;
  config: BaseConfig;
  logger?: Logger;
};

export function Adobe({ analytics, config, logger }: AdobeProps) {
  useEffect(() => {
    const unsubscribe = analytics.subscribe({
      async initialize(data) {
        if (isUndefined(config.sdks.adobeAnalytics.dtmUrl)) {
          logger?.error(`'config.sdks.adobeAnalytics.dtmUrl' is not set`);
          return;
        }

        await loadScript(config.sdks.adobeAnalytics.dtmUrl, {
          async: true,
          id: 'adobe-satellite',
          replace: true,
        });

        await waitUntil(() => !!window._satellite);

        const visitor = window._satellite?.getVisitorId?.();

        const date = new Date();

        const adobeVersion = window?.tracker?.version;

        let visitorId: string | undefined;

        try {
          visitorId = visitor?.getMarketingCloudVisitorID?.();
        } catch (error) {
          logger?.error(`Error getting visitorId: ${error}`);
        }

        window.analyticsData = {
          global: {
            ...data,
            adobeVersion,
            device: {
              ...data.device,
              visitorSessionCount: window._satellite?.visitorSessionCount?.(),
              reportedDate: date.getTime(),
            },
            user: { ...data.user, visitorId },
          },
        };
      },

      track(payload) {
        if (isUndefined(window._satellite)) {
          return;
        }

        const trackType: AdobeEventType =
          payload.type === eventType.enum.PageView ?
            'trackPage'
          : 'trackAction';

        // Adobe is expecting string values for row/column, even though spec calls for integer
        // This is a hacky solution to force a string for row/column if defined
        if (payload.type === eventType.enum.ItemSelected) {
          payload.data.view.item.column = String(
            payload.data.view.item.column,
          ) as unknown as number;
        }

        if (payload.type === eventType.enum.ItemSelected) {
          payload.data.view.item.row = String(
            payload.data.view.item.row,
          ) as unknown as number;
        }

        const currentEvent = {
          [payload.type]: payload.data,
        };

        window.analyticsData.events =
          window.analyticsData.events ?
            mergeObjects(window.analyticsData?.events, currentEvent)
          : currentEvent;

        // We need to update the global data as some device info eg: isPlaying gets updated
        if (window.analyticsData.global?.device && payload.device) {
          window.analyticsData.global.device = {
            ...window.analyticsData.global.device,
            ...payload.device,
          };
        }

        window.analyticsData = {
          global: {
            ...window.analyticsData.global,
          },
          events: {
            ...window.analyticsData?.events,
            active: {
              action: payload.type,
              ...payload.data,
            },
          },
        };

        window._satellite.track(trackType);
      },
    });

    return unsubscribe;
  }, [analytics, config.sdks.adobeAnalytics.dtmUrl, logger]);

  return null;
}
