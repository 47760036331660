import { Adobe } from '@iheartradio/web.analytics/adobe';
import type { User } from '@iheartradio/web.config';
import { isUndefined, uuid } from '@iheartradio/web.utilities';
import { useEffect } from 'react';

import { useAnalytics } from '~app/analytics';
import { useConfig } from '~app/contexts/config';
import { useRootLoaderData } from '~app/hooks/use-root-loader-data';

import { Igloo } from './igloo';

function getUserInformationForAnalytics(user?: User) {
  return !isUndefined(user) ?
      {
        abTestGroup:
          user.abTestGroups &&
          Object.entries(user.abTestGroups).map(
            ([key, value]) => `${key}|${value}`,
          ),
        genreIsDefault: false,
        genreSelected: [],
        isTrialEligible: user.subscription?.isTrialEligible ?? false,
        privacyOptOut: user.privacy?.hasOptedOut ?? false,
        profileId: user.profileId.toString(),
        registration: {
          birthYear: user.birthYear,
          country: user.country,
          gender: user.gender,
          type: user?.oauths?.[0]?.type?.toUpperCase() ?? 'IHR',
          zip: user.zipCode,
        },
        subscriptionTier: user.subscription?.type ?? 'NONE',
        skuPromotionType: user.subscription?.productId,
      }
    : {
        abTestGroup: [],
        genreIsDefault: false,
        genreSelected: [],
        isTrialEligible: false,
        privacyOptOut: false,
        profileId: uuid(),
        registration: {
          birthYear: 0,
          country: '',
          gender: '',
          type: 'NONE',
          zip: '',
        },
        subscriptionTier: 'NONE',
        skuPromotionType: '',
      };
}

export function Analytics({ user }: { user?: User }) {
  const { geolocation, appVersion } = useRootLoaderData();
  const analytics = useAnalytics();
  const config = useConfig();

  useEffect(() => {
    if (!isUndefined(globalThis.window) && !isUndefined(analytics)) {
      if (analytics.initialized) return;
      analytics.initialize({
        device: {
          appVersion,
          id: uuid(),
          lat: geolocation.lat,
          lng: geolocation.lng,
          host: config.environment.hosts.account,
          isPlaying: false,
          volume: 50,
        },
        user: getUserInformationForAnalytics(user),
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      analytics.setGlobalData({
        user: getUserInformationForAnalytics(user),
      });
    }
  }, [user]);

  return (
    <>
      <Igloo />
      <Adobe analytics={analytics} config={config} />
    </>
  );
}
