/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-duplicate-type-constituents */
import { invariant } from '@epic-web/invariant';
import { type Theme, ThemeEnum } from '@iheartradio/web.accomplice';
import { useRouteLoaderData } from '@remix-run/react';
import { isNonNullish } from 'remeda';

export interface RequestInfo {
  hints: {
    theme: Theme;
    reducedMotion: 'reduce' | 'no-preference';
    timeZone: string;
  };
  userPrefs: {
    theme: Theme | null;
  };
  locale: string;
  isMobile: boolean;
}

/**
 * @returns the request info from the root loader
 */
function useRequestInfo<T extends RequestInfo>(rootError: true): RequestInfo;
function useRequestInfo<T extends RequestInfo>(rootError: false): T;
function useRequestInfo<T extends RequestInfo>(rootError: undefined): T;
function useRequestInfo<T extends RequestInfo>(): T;
function useRequestInfo<T extends RequestInfo>(
  rootError?: boolean | undefined,
): typeof rootError extends true ? RequestInfo : T;
function useRequestInfo<T extends RequestInfo>(
  rootError?: boolean,
): typeof rootError extends true ? RequestInfo : T {
  const data = useRouteLoaderData('root') as {
    requestInfo?: T | undefined;
  };

  if (rootError) {
    // Root error pages return a default light theme RequestInfo
    return {
      hints: {
        reducedMotion: 'no-preference',
        theme: ThemeEnum.light,
        timeZone: 'UTC',
      },
      userPrefs: { theme: ThemeEnum.light },
      isMobile: false,
      locale: 'en-US' as const,
    } as any;
  }

  invariant(
    isNonNullish(data?.requestInfo),
    'No requestInfo found in root loader',
  );

  return data.requestInfo as any;
}

export { useRequestInfo };
