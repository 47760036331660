import type { Primitive } from 'type-fest';

import { isNil, isUndefined } from '../typeof/index.js';

export const isEmptyString = (s: string): boolean =>
  typeof s === 'string' && s?.trim().length === 0;

export const isEmptyObject = (o: Record<string, any>): boolean =>
  Object.keys(o).length === 0;

export const filterEntries = <O extends Record<string, any>>(
  predicate: (value: [string, unknown], index?: number) => unknown,
  o?: O | null,
): Record<string, any> =>
  isNil(o) ? {} : Object.fromEntries(Object.entries(o).filter(predicate));

// Removes key/value pairs from an object where the value is null or undefined
export const removeNilValues = <O extends Record<string, unknown>>(o: O) =>
  filterEntries(([_k, v]) => !isNil(v), o);

// Stolen from https://github.com/sindresorhus/is-plain-obj
export function isPlainObject<Value>(
  value: unknown,
): value is Record<PropertyKey, Value> {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const prototype = Object.getPrototypeOf(value);
  return (
    (prototype === null ||
      prototype === Object.prototype ||
      Object.getPrototypeOf(prototype) === null) &&
    !(Symbol.toStringTag in value) &&
    !(Symbol.iterator in value)
  );
}

export function toFormData(o: Record<string, unknown>) {
  return Object.entries(o).reduce((d, [key, value]) => {
    if (value != null) {
      d.append(key, typeof value !== 'string' ? JSON.stringify(value) : value);
    }
    return d;
  }, new FormData());
}

export const removeNilValuesFromObject = (
  o: Record<string, Primitive>,
): Record<string, string> => {
  return Object.fromEntries(
    Object.entries(o)
      .filter(([_key, value]) => !isNil(value))
      .map(([key, value]) => [key, String(value)]),
  );
};

export function stringify(
  object: Record<PropertyKey, unknown>,
  options?: { delimiter?: string; removeNil?: boolean },
) {
  const delimiter = options && options.delimiter ? options.delimiter : '&';
  const removeNil =
    options && !isUndefined(options.removeNil) ? options.removeNil : true;

  return Object.entries(removeNil ? removeNilValues(object) : object)
    .reduce(
      (accumulator, [key, value]) => [
        ...accumulator,
        `${String(key)}=${String(value)}`,
      ],
      [] as string[],
    )
    .join(delimiter);
}

export function mergeObjects(...objs: Record<string, any>[]) {
  return [...objs].reduce(
    (acc, obj) =>
      Object.keys(obj).reduce((_, k) => {
        acc[k] =
          Object.prototype.hasOwnProperty.call(acc, k) ?
            [acc[k]].flat().concat(obj[k])
          : obj[k];
        return acc;
      }, {}),
    {},
  );
}

export function flattenObject(
  object: Record<PropertyKey, unknown>,
  delimiter = '.',
  prefix = '',
) {
  return Object.keys(object).reduce(
    (accumulator, key) => {
      const pre = prefix.length > 0 ? `${prefix}${delimiter}` : '';
      if (
        typeof object[key] === 'object' &&
        object[key] !== null &&
        Object.keys(object[key]).length > 0
      ) {
        Object.assign(
          accumulator,
          flattenObject(
            object[key] as Record<PropertyKey, unknown>,
            delimiter,
            `${pre}${key}`,
          ),
        );
      } else {
        accumulator[`${pre}${key}`] = object[key];
      }
      return accumulator;
    },
    {} as Record<PropertyKey, unknown>,
  );
}
